[
  {
    "Name": "Nasty Rumours",
    "Genre": "77 Punkrock/Powerpop",
    "Info": "Ihr steht auf Bands wie Buzzcocks, Vibrators oder The Boys? Zum Outside Rodeo 2025 rollt aus der coolsten Stadt der Schweiz der Young Boys Express an den Alten Güterbahnhof. Die vier Berner liefern Punkrock, der abgeht wie tanzende Rasierklingen auf den Straßen Londons – damals, im Sommer des Wahnsinns 1977. Wir können ihre Ankunft kaum erwarten... Für euch! Für uns! Nasty Rumours! Cheers!",
    "City": "Bern",
    "Country": "CH",
    "Image": "2025_nastyrumours_quad.jpg"
  },
  {
    "Name": "Wick Bambix",
    "Genre": "Accoustic Folk Punk",
    "Info": "Leidenschaftliche und rebellische Songs mit Wurzeln in Country, Folk und Punk - das alles mit messerscharfen Texten, vorgetragen auf melodramatische Weise und mit erhobener Faust. Wick Bambix, die Gründerin der legendären All-Girl-Punkrock-Band Bambix aus Nijmegen in den Niederlanden, wird beim Outside Rodeo 2025 die Sideshow Stage entern und euch bezaubern. Welkom, Wick!",
    "City": "Nijmegen",
    "Country": "NL",
    "Image": "2025_wickbambix_quad.jpg"
  },
  {
    "Name": "The Anti-Queens",
    "Genre": "Punk/Rock'n Roll",
    "Info": "Die Drähte zwischen unserem Booking-Büro und Belvedere-Sänger und Booker Steve Rawles in Kanada sind seit Steves Besuch im Toxic Toast mehr als gut. Kein Wunder also, dass er uns seine Rock'n'Roll-Superheldinnen aus Toronto vorbeischickt, bevor es weiter zum Punk Rock Holiday geht. The Anti-Queens werden mit ihren Hymnen und ihren trotzigen Wutausbrüchen für ordentlich Bewegung vor der Bühne sorgen. See you at the Railrodeo Stage!",
    "City": "Toronto",
    "Country": "CA",
    "Image": "2025_theantiqueens_quad.jpg"
  },
  {
    "Name": "Faintest Idea",
    "Genre": "Sonic-Blast-Punk",
    "Info": "Habt ihr am Ende eines lauen Sommerabends mal wieder Lust auf Totalabriss auf der Railrodeo Stage? Dann haben wir genau das Richtige für euch: Inspiriert von Rancid und The Specials erwartet euch energiegeladener Ska-Punk mit zwei Vokalisten, gemixt mit dem fesselnden Brass-Sound einer amtlichen Bläserfraktion auf euch. Aus King's Lynn haben sie sich über die Jahre hinweg eine immer größere Fangemeinde in UK und auf dem europäischen Festland erspielt. Alles vorbereitet für eine rasende Nacht am Alten Güterbahnhof. War to the palaces. Don't miss it! FAINTEST IDEA!!!!!",
    "City": "King's Lynn",
    "Country": "GB",
    "Image": "2025_faintestidea_quad.jpg"
  },
  {
    "Name": "The Snouters",
    "Genre": "Ska-Punk",
    "Info": "Aus dem Sehnsuchtsland Italien kommen im Juli 2025 The Snouters an den Alten Güterbahnhof. Mit Mitgliedern von Skassapunka und Los Fastidios sind sie fast schon alte Bekannte. Freut euch auf absolut furiosen, tanzbaren Ska-Punk, der wie die Faust aufs Auge zum Outside Rodeo passt. Dazu liefern Female Voices im „Interrupters-Style“ den perfekten Schuss California Punkrock. Benvenuti, The Snouters!",
    "City": "Milan",
    "Country": "IT",
    "Image": "2025_thesnouters_quad.jpg"
  },
  {
    "Name": "Subzero Topdogs",
    "Genre": "Punk-Rock",
    "Info": "Im Jahr 2013 noch auf der Nebenbühne „Bei Adam“, kehrt die Bamberger Punk-Institution Subzero Topdogs zum Outside Rodeo zurück - dieses Mal auf die Railrodeo Stage! Seit drei Jahrzehnten ist das Powertrio (1992 noch als klassische Fünfer-Besetzung gegründet) ein fester Bestandteil der fränkischen Szene. Mit Geschichten über den normalen Wahnsinn des Lebens und die sonstigen Ups and Downs begeistern sie ihr Publikum – ungeachtet jeglicher Trends und Hypes. Ihr Programm umfasst Hits aus über 30 Jahren Bandgeschichte, ergänzt durch neue Songs, die rechtzeitig zum Festival auf einem neuen Tonträger veröffentlichen werden. Punkrock straight in your face!",
    "City": "Bamberg",
    "Country": "DE",
    "Image": "2025_subzerotopdogs_quad.jpg"
  },
  {
    "Name": "Private Function",
    "Genre": "Rock'n'Roll",
    "Info": "Private Function aus Melbourne haben sich in den letzten sieben Jahren den Ruf als eine der explosivsten, unberechenbarsten und unverzichtbarsten Rock'n'Roll-Bands Australiens erarbeitet. Nun setzen sie die Tradition legendärer Auftritte australischer Bands beim Outside Rodeo – wie Comic Psychos und Clowns – fort. Euch erwartet eine explosive Mischung aus frecher Schlagfertigkeit, nostalgisch angehauchten Witzen und rebellischem Charme. Ein brutal hittiger Mix aus Punk und Garage, angereichert mit Elementen aus Hardcore, Trash, Powerpop und Pubrock. Cheers!!!",
    "City": "Melbourne",
    "Country": "AU",
    "Image": "2025_privatefunction_quad.jpg"
  },
  {
    "Name": "D.I.",
    "Genre": "Oldschool Punk-Rock",
    "Info": "Unser nächstes Highlight beamt uns per Zeitmaschine in die 80er Jahre an die Küste Kaliforniens. Social Distortion, Adolescents, Agent Orange, TSOL und… ja, genau: D.I.!!! Diese Orange-County-Punkrock-Legende um Sänger und Gründungsmitglied Casey Royer beehrt uns am Alten Güterbahnhof. Royer hatte die Idee für Social Distortion und gründete die Band zusammen mit Mike Ness, bevor er sich ab 1981 aufmachte, um mit D.I. zur Legende zu werden. Die Kalifornier haben auch nach über vierzig Jahren nichts von ihrer Faszination eingebüßt. Freut euch auf Skatecoreklassiker wie „Hang Ten In East Berlin“ am laufenden Band und auf eine rasante, spaßige Zeitreise mit Hi-Energy-Songs perfekt für den heißen Sommer auf euren Skateboards!",
    "City": "Fullerton",
    "Country": "US",
    "Image": "2025_di_quad.jpg"
  },
  {
    "Name": "Matze Rossi",
    "Genre": "Singer-Songwriter",
    "Info": "Ein weiterer guter und alter Freund gibt sich am Alten Güterbahnhof die Ehre. Wie oft haben wir ihn und mit ihm gefeiert? Ob mit der Schweinfurter Punk-Legende Tagtraum oder mit seinem Side-Project Bad Drugs beim Outside Rodeo 2014. Der wunderbare Singer-Songwriter Matze Rossi, der es liebt, Geschichten aus dem echten Leben in Musik zu verwandeln, wird am Outside Rodeo 2025 die Sideshow Stage headlinen. Matze Rossis Konzerte sind nicht nur eine musikalische Erfahrung, sondern auch eine Gelegenheit, Gleichgesinnte zu treffen. Die Energie und Verbundenheit mit anderen Hörer*innen, die seine Musik schätzen, macht jedes Konzert zu einem besonderen Ereignis. Überzeugt euch selbst!",
    "City": "Schweinfurt",
    "Country": "DE",
    "Image": "2025_matzerossi_quad.jpg"
  },
  {
    "Name": "Jonny Karacho",
    "Genre": "Kinderlieder-Pogo",
    "Info": "Am Samstagnachmittag und frühen Abend wird es auch für unsere superjungen Nachwuchs-Punker ein Programm-Highlight geben: Denn wir wollen keine fleißigen Handwerker sehen und auch keine Kokosnüsse klauen. Jonny Karacho greift zur Gitarre und erschafft mit einer Mischung aus Folk, Ska, Pop und Punk neue Songs, die frischen Wind in die Kinderzimmer bringt. Wild, witzig, frech und liebevoll - das ist Jonny Karacho, das ist Punkrock für Kids! Also, liebe Eltern: Holt eure Dr. Martens raus und tanzt mit euren Kindern über den Alten Güterbahnhof, denn: PUNK IS NOT DEAD!",
    "City": "Darmstadt",
    "Country": "DE",
    "Image": "2025_jonnykaracho_quad.jpg"
  },
  {
    "Name": "T(h)reee",
    "Genre": "Melodic Punkrock",
    "Info": "Zeitreise. 1997. Peesten. Ein Festival im fränkischen Niemandsland. Kein Handy. Kein Internet. Kein Schlaf. Nur Freunde. 20 Stunden im Hier und Jetzt. T(h)reee spielen, du tanzt vor der Bühne. Du kaufst die Single. Dauerrotation auf dem WG-Plattenspieler. Du hoffst, die Jungs zu treffen: im Oylnspygl, in der Etage, im Komm. Punker Freundschaften in den Neunzigern. Presseinfo? Bandfoto? Für Social Media? Was wollt ihr von uns? Wir kommen. Wir sind pünktlich. Wir freuen uns auf Euch. Wir spielen energiegeladenen, melodischen Punkrock. Wir sind T(h)reee.",
    "City": "Peesten",
    "Country": "DE",
    "Image": "2025_threee_quad.jpg"
  },
  {
    "Name": "OBO Band",
    "Genre": "Metal, Punk, Schlager",
    "Info": "Sie gehören zum Outside Rodeo wie der Bühnen-Büffelkopf und kaltes Bier: Unsere Buddys von der OBO Band. Laut, bunt und einzigartig – das sind sie! Auch 2025 freuen wir uns auf ihre Hits auf der kleinen Bühne am Gleis. Kommt vorbei, saugt diese so selten gewordene Stimmung von Mitmenschlichkeit und Solidarität auf und tragt sie mit in euren Alltag.",
    "City": "Coburg",
    "Country": "DE",
    "Image": "2025_oboband_quad.jpg"
  },
  {
    "Name": "John Steam Jr.",
    "Genre": "Singer-Songwriter",
    "Info": "John Steam Jr. verbindet auf grundsympathische Weise intelligentes, introspektives Songwriting mit hohem Mitsingfaktor und einer tiefen Faszination für den wüstenstaubigen Nashville/Tennessee-Sound. Das passt genauso gut in den zugestickerten DIY-Laden wiezwischen Hühnerstall und Traktor - oder auf die große Festivalbühne. Supportshows für Joey Cape, Frank Turner, Larry and his Flask, Rosco Wuestewald, Meadows uvm. Haben gezeigt: Zwischen Akustikgitarre und Hut serviert JSJR einen wohltemperierten Cocktail aus Pop, Punk und Country – mit Charme, Witz und entwaffnender Ehrlichkeit. Prost!",
    "City": "Nürnberg",
    "Country": "DE",
    "Image": "2025_johnsteam_quad.jpg"
  }
]
