import Alpine from 'alpinejs';
import LineUpVorab from './../../data/lineup-vorab.json';
// import LineUp1JSON from './../../data/lineup-freitag.json';
// import LineUp2JSON from './../../data/lineup-samstag.json';
import FaqJSON from './../../data/faq.json';
import SponsorsJSON from './../../data/sponsoren.json';
import { Fancybox } from '@fancyapps/ui';

window.Alpine = Alpine;
Alpine.store('lineup', LineUpVorab);
// Alpine.store('lineup1', LineUp1JSON);
// Alpine.store('lineup2', LineUp2JSON);
Alpine.store('faq', FaqJSON);
Alpine.store('sponsors', SponsorsJSON);
Alpine.start();

const bodyEl: HTMLElement = document.body;
bodyEl.classList.remove('no-js');
window.addEventListener('load', () => {
  setTimeout(() => {
    bodyEl.classList.add('body--hydrated');
  }, 500);
});

Fancybox.bind('[data-fancybox]', {});
